import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';

import {FormikFileInput, FormikSelect, StatefulButton, useAlerts} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {courseApi, loggedCourseApi} from '../../api';
import {logCourseFormSchema} from '../../schema';
import {CertificationStatus, CertificationYear, Course, LoggedCourse} from '../../types';
import {LogCourseFormFields} from '../../types/forms';

type Props = {
  isOpen: boolean
  certificationYear: CertificationYear
  certificationStatus: CertificationStatus
  onToggle: (loggedCourse?: LoggedCourse) => void
}

const LogCourseModal = ({
                          isOpen,
                          certificationYear,
                          certificationStatus,
                          onToggle
                        }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [coursesToDisplay, setCoursesToDisplay] = useState<Course[]>([]);
  const initialValues: LogCourseFormFields = useMemo(() =>
      ({courseId: null, proofOfCompletionFile: undefined}),
    []);

  const handleSubmit = useCallback(async ({
                                            courseId,
                                            proofOfCompletionFile
                                          }: LogCourseFormFields, formikHelpers: FormikHelpers<LogCourseFormFields>) => {
    try {
      const logCourseRequest = new FormData();
      logCourseRequest.append('courseId', courseId ? courseId.toString() : '');
      logCourseRequest.append('proofOfCompletionFile', proofOfCompletionFile as File);

      const loggedCourse = await loggedCourseApi.create(logCourseRequest);
      formikHelpers.setSubmitting(false);
      showSuccessAlert(messages.LOGGED_COURSE_SUCCESSFUL);
      onToggle(loggedCourse);
    } catch (error) {
      formikHelpers.setSubmitting(false);
      showErrorAlert(messages.LOGGED_COURSE_FAILED);
      onToggle();
    }
  }, [onToggle, showErrorAlert, showSuccessAlert]);

  const handleClose = useCallback((formikProps: FormikProps<LogCourseFormFields>) => {
    formikProps.resetForm();
    onToggle();
  }, [onToggle]);

  useEffect(() => {
    const loadCourses = async () => {
      if (certificationYear && certificationYear.loggingCoursesAllowed) {
        try {
          const courses = await courseApi.findAllBy(certificationYear.value, false);
          const loggedCourseIds = certificationStatus.loggedCourses
            .map(loggedCourse => loggedCourse.course.id);
          const coursesToDisplay = courses
            .filter(course => {
              const isSelfPacedAndAtMax = course.classFormat === 'SELF_PACED' &&
                !course.stcUpdate &&
                certificationStatus.atSelfPacedMax;
              return !loggedCourseIds.includes(course.id) && !isSelfPacedAndAtMax;
            });
          setCoursesToDisplay(coursesToDisplay);
        } catch (error) {
          showErrorAlert(messages.COURSES_LOAD_FAILED);
        }
      }
    };

    void loadCourses();
  }, [certificationYear, certificationStatus, showErrorAlert]);

  const renderCourseOption = useCallback(({
                                            id,
                                            name,
                                            courseNumber,
                                            instructor,
                                            courseDateDisplay
                                          }: Course) => {
    const dropdownValue = `${name} ${courseNumber} - ${instructor} - ${courseDateDisplay === null ? 'N/A' : courseDateDisplay}`;
    return <option key={id}
                   value={id}>
      {dropdownValue}
    </option>;
  }, []);

  return (
    <Formik initialValues={initialValues}
            validationSchema={logCourseFormSchema}
            validateOnMount
            onSubmit={handleSubmit}>
      {(formikProps) => {
        return (
          <Modal className="LogCourseModal"
                 autoFocus={false}
                 backdrop="static"
                 isOpen={isOpen}
                 size="lg"
                 toggle={() => handleClose(formikProps)}>
            <ModalHeader toggle={() => handleClose(formikProps)}>
              Log Continuing Education Course
            </ModalHeader>
            <Form onSubmit={formikProps.handleSubmit}
                  autoComplete="off">
              <ModalBody>
                <Row>
                  <Col>
                    <FormikSelect name="courseId"
                                  labelText="Select Course"
                                  autoFocus
                                  aria-required={true}>
                      <option value="">Select</option>
                      {coursesToDisplay.map(renderCourseOption)}
                    </FormikSelect>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormikFileInput name="proofOfCompletionFile"
                                     labelText="Upload Proof of Completion"/>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="pr-0 pl-0">
                <Row className="w-100 d-flex m-0">
                  <Col className="align-self-center text-secondary font-weight-bold" sm="8">
                    You may complete a maximum of 8 hours of self-paced courses.
                  </Col>
                  <Col className="align-self-center justify-content-end d-flex" sm="4">
                    <StatefulButton activeState={formikProps.isSubmitting ? 'submitting' : 'default'}
                                    states={[
                                      {name: 'default', text: 'Log Course'},
                                      {name: 'submitting', text: 'Logging Course', icon: 'spinner', spinIcon: true}
                                    ]}
                                    color="success"
                                    className="mr-2"
                                    onClick={formikProps.submitForm}
                                    disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                    </StatefulButton>
                    <Button color="secondary"
                            onClick={() => handleClose(formikProps)}
                            disabled={formikProps.isSubmitting}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </ModalFooter>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default memo(LogCourseModal);